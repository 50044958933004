import { generateKeywordWorkbook, applyWorksheetStyling, applyZebraStriping } from './keywordExportUtils.js'

/**
 * Örnek verilerle şablon Excel dosyası oluşturur
 * @returns {Promise<Object>} Workbook ve worksheet objeleri
 */
export async function generateTemplateWorkbook() {
  // Yeni workbook oluştur
  const { workbook, worksheet } = generateKeywordWorkbook()

  // Örnek veriler
  const sampleData = [
    { keyword: 'keyword 1', priorityScore: '1' },
    { keyword: 'keyword 2', priorityScore: '2' },
  ]

  // Verileri ekle ve stilleri uygula
  sampleData.forEach((item, index) => {
    worksheet.addRow({
      keyword: item.keyword,
      priorityScore: item.priorityScore,
    })

    applyZebraStriping(worksheet, index)
  })

  applyWorksheetStyling(worksheet)

  return { workbook, worksheet }
}

/**
 * Keyword verilerini Excel buffer'ına dönüştürür
 * @param {Array} keywords - Keyword verileri
 * @returns {Promise<Buffer>} Excel dosyası buffer'ı
 */
export async function exportKeywordsToBuffer(keywords) {
  // Yeni workbook oluştur
  const { workbook, worksheet } = generateKeywordWorkbook()

  // Verileri ekle ve stilleri uygula
  keywords.forEach((keyword, index) => {
    worksheet.addRow({
      keyword: keyword.name,
      priorityScore: keyword.priority || '', // priorityScore yerine priority kullanıldığını varsaydım
    })

    applyZebraStriping(worksheet, index)
  })

  applyWorksheetStyling(worksheet)

  // Buffer olarak döndür
  return workbook.xlsx.writeBuffer()
}