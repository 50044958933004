import { KeywordUtils } from '@/utils/keywordUtils'

/**
 * Aktif (silinmemiş) keywordleri filtreler
 * @param {Array} keywords - Keyword dizisi
 * @returns {Array} Aktif keywordler
 */
export function filterActiveKeywords(keywords) {
  return keywords.filter((keyword) => keyword.state !== 3)
}

/**
 * Normalize edilmiş ve tekil keywordlerden oluşan bir Map oluşturur
 * @param {Array} keywords - Keyword dizisi
 * @returns {Map} Normalize edilmiş keyword Map'i
 */
export function createNormalizedKeywordMap(keywords) {
  const keywordMap = new Map()

  keywords.forEach((keyword) => {
    const normalizedName = KeywordUtils.normalize(keyword.name)
    const existing = keywordMap.get(normalizedName)

    if (shouldReplaceExisting(existing, keyword)) {
      keywordMap.set(normalizedName, keyword)
    }
  })

  return keywordMap
}

/**
 * Mevcut keyword'ün yenisiyle değiştirilip değiştirilmeyeceğini kontrol eder
 * @param {Object|undefined} existing - Mevcut keyword
 * @param {Object} newKeyword - Yeni keyword
 * @returns {boolean} Değiştirilmeli mi?
 */
export function shouldReplaceExisting(existing, newKeyword) {
  if (!existing) return true

  if (newKeyword.state > existing.state) return true

  if (newKeyword.state === existing.state) {
    if (newKeyword.id > existing.id) return true
    if (newKeyword.keywordId > existing.keywordId) return true
  }

  return false
}

/**
 * Keyword objesini tablo formatına dönüştürür
 * @param {Object} keyword - Keyword objesi
 * @returns {Object} Tablo formatına dönüştürülmüş keyword
 */
export function mapKeywordToTableFormat(keyword) {
  return {
    id: keyword.id || keyword.keywordId,
    keywordId: keyword.keywordId,
    name: keyword.name || '',
    state: keyword.state,
    priority: keyword.priority,
    categoryIds: keyword.categoryIds?.map(category => ({
      id: category.id,           // keywordCustomCategories tablosunun ID'si
      categoryId: category.categoryId, // kategorinin ID'si
      state: category.state || 1 // default: 1 (mevcut)
    })) || [],
    isNew: keyword.state === 2,
  }
}

/**
 * Keywordleri öncelik ve isme göre sıralar
 * @param {Array} keywords - Keyword dizisi
 * @returns {Array} Sıralanmış keyword dizisi
 */
export function sortKeywordsByPriorityAndName(keywords) {
  return [...keywords].sort((a, b) => {
    // Öncelik farklıysa, önceliğe göre sırala
    if (a.priority !== b.priority) {
      return (b.priority || 0) - (a.priority || 0)
    }

    // Öncelik aynıysa, isme göre sırala
    const nameA = a.name || ''
    const nameB = b.name || ''
    return nameA.localeCompare(nameB)
  })
}