<template>
  <VueFinalModal v-model="showModal" @opened="fetchKeywords" @closed="closeModal" class="flex justify-center items-center" content-class="flex flex-col p-6 bg-white rounded-lg shadow-xl max-w-7xl w-full h-[90vh] sm:h-[80vh]">
    <div class="flex flex-col h-full">
      <!-- Header -->
      <div class="flex justify-between items-center mb-6">
        <div>
          <h2 class="text-xl font-semibold text-gray-800">
            {{ mainKeyword.name }} </h2>
          <p class="text-sm text-gray-500">Alt Keywordler</p>
        </div>
        <button @click="closeModal" class="text-gray-400 hover:text-gray-600">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Search and Sort -->
      <div class="flex flex-wrap gap-4 mb-4">
        <div class="flex-1 my-0.5">
          <input v-model="searchQuery" type="text" placeholder="Keyword ara..." class="w-full px-3 py-1.5 border border-gray-200 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"/>
        </div>
        <div class="flex-1 flex  space-x-4">
          <!-- Kaynak Filtresi -->
          <select v-model="sourceFilter" class="px-3 my-0.5 border rounded-md hover:bg-gray-50 bg-white cursor-pointer flex items-center gap-2  text-sm w-full">
            <option value="all">Tüm Kaynaklar</option>
            <option value="manual">Manuel</option>
            <option value="suggested">Önerilen</option>
          </select>

          <!-- Gelişmiş Sıralama -->
          <select v-model="sortField" class="px-3 my-0.5 border rounded-md hover:bg-gray-50 bg-white cursor-pointer flex items-center gap-2  text-sm w-full">
            <option value="volume">Hacim</option>
            <option value="yandex">Yandex Sıralama</option>
            <option value="google">Google Sıralama</option>
            <option value="bing">Bing Sıralama</option>
          </select>
          <!--          <DateRangePicker v-model="datePickerModel" :currentYearOnly="true" class="w-full my-0.5" />-->
          <!-- Sıralama Yönü -->
          <button @click="toggleSortDirection" class="border flex items-center justify-center my-0.5 px-3 border-gray-200 rounded-lg hover:bg-gray-50">
            <span class="material-symbols-outlined !text-[20px]">
              {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
            </span>
          </button>
        </div>
      </div>

      <!-- Loading State -->
      <div v-if="isLoading" class="flex-1 flex justify-center items-center">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>

      <!-- Error State -->
      <div v-else-if="error" class="flex-1 flex justify-center items-center">
        <div class="text-red-500 text-center">
          <p class="text-lg mb-2">{{ error }}</p>
          <button @click="fetchKeywords" class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Tekrar Dene
          </button>
        </div>
      </div>

      <!-- Table -->
      <div v-else class="flex-1 overflow-auto border border-gray-200 rounded-lg">
        <table class="w-full">
          <thead class="bg-gray-50 sticky top-0">
          <tr>
            <th class="px-4 py-3 text-left text-sm font-medium text-gray-600">
              Keyword
            </th>
            <th class="px-4 py-3 text-center text-sm font-medium text-gray-600">
              Kaynak
            </th>
            <th class="px-4 py-3 text-center text-sm font-medium text-gray-600">
              Hacim
            </th>
            <th class="px-4 py-3 text-center text-sm font-medium text-gray-600">
              Yandex Sıralama
            </th>
            <th class="px-4 py-3 text-center text-sm font-medium text-gray-600">
              Google Sıralama
            </th>
            <th class="px-4 py-3 text-center text-sm font-medium text-gray-600">
              Bing Sıralama
            </th>
          </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
          <template v-if="sortedKeywords.length">
            <tr v-for="keyword in sortedKeywords" :key="keyword.id" class="hover:bg-gray-50">
              <td class="px-4 py-3">
                <div class="flex items-center gap-2">
                    <span v-if="keyword.suggested" class="material-symbols-outlined text-yellow-400 text-sm">
                      star
                    </span> <span class="text-gray-700">{{ keyword.name }}</span>
                </div>
              </td>
              <td class="px-4 py-3 text-center">
                  <span class="text-sm" :class="keyword.suggested ? 'text-red-600' : 'text-gray-600'
                    ">
                    {{ keyword.suggested ? 'Önerilen' : 'Manuel' }}
                  </span>
              </td>
              <td class="px-4 py-3 text-center text-gray-600">
                {{ Number(keyword.volume || 0).toLocaleString() }}
              </td>
              <td class="px-4 py-3 text-center text-gray-600">
                {{
                  keyword.position.length >= 1
                      ? (Number(keyword.position[0].position) > 100 || Number(keyword.position[0].position) === 0 ? '100+' : keyword.position[0].position)
                      : '100+'
                }}
              </td>
              <td class="px-4 py-3 text-center text-gray-600">
                {{
                  keyword.position.length >= 2
                      ? (Number(keyword.position[1].position) > 100 || Number(keyword.position[1].position) === 0 ? '100+' : keyword.position[1].position)
                      : '100+'
                }}
              </td>
              <td class="px-4 py-3 text-center text-gray-600">
                {{
                  keyword.position.length >= 3
                      ? (Number(keyword.position[2].position) > 100 || Number(keyword.position[2].position) === 0 ? '100+' : keyword.position[2].position)
                      : '100+'
                }}
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="6" class="px-4 py-8">
              <div class="flex flex-col items-center justify-center text-gray-500">
                <span class="material-symbols-outlined text-4xl mb-2">search_off</span>
                <p class="text-base">
                  Arama kriterlerinize uygun sonuç bulunamadı </p>
                <p class="text-sm mt-1">
                  Farklı bir arama terimi veya filtre kullanarak tekrar deneyebilirsiniz </p>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'
import DateRangePicker from '@/components/base/inputs/VDateRangePicker.vue'

import moment from 'moment'

const props = defineProps({
  modelValue: Boolean,
  mainKeyword: {
    type: Object,
    required: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const isLoading = ref(false)
const keywords = ref([])
const searchQuery = ref('')
const sortBy = ref('volume')
const error = ref(null)
const assetStore = useAssetStore()
const datePickerModel = ref({ start: null, end: null })

const sourceFilter = ref('all')
const sortField = ref('volume')
const sortDirection = ref('desc')

const toggleSortDirection = () => {
  sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc'
}

const sortedKeywords = computed(() => {
  // Adım adım filtreleme ve sıralama işlemleri
  const searchFiltered = applySearchFilter(keywords.value)
  const sourceFiltered = applySourceFilter(searchFiltered)
  return sortItems(sourceFiltered, sortField.value, sortDirection.value)
})

// Arama filtresini uygula
const applySearchFilter = (items) => {
  return items.filter((keyword) =>
      keyword.name.toLowerCase().includes(searchQuery.value.toLowerCase()),
  )
}

// Kaynak filtresini uygula
const applySourceFilter = (items) => {
  if (sourceFilter.value === 'all') return items

  const isShowingSuggested = sourceFilter.value === 'suggested'
  return items.filter((keyword) =>
      isShowingSuggested ? keyword.suggested : !keyword.suggested,
  )
}

// Sıralama işlevi
const getSortingValue = (item, field) => {
  switch (field) {
    case 'volume':
      return Number(item.volume || 0)
    case 'yandex':
      return Number(item.position[0]?.position || 0)
    case 'google':
      return Number(item.position[1]?.position || 0)
    case 'bing':
      return Number(item.position[2]?.position || 0)
    default:
      return 0
  }
}

// Sıralama fonksiyonu
const sortItems = (items, field, direction) => {
  return [...items].sort((a, b) => {
    const valueA = getSortingValue(a, field)
    const valueB = getSortingValue(b, field)

    // Hacim için büyükten küçüğe, diğerleri için küçükten büyüğe doğal sıralama
    const naturalOrder = field === 'volume'
        ? valueB - valueA
        : valueA - valueB

    // Sıralama yönüne göre sonucu çevir
    return direction === 'asc' ? -naturalOrder : naturalOrder
  })
}

watch(datePickerModel, (newValue) => {
  // Only fetch if both start and end dates are selected
  if (newValue.start && newValue.end) {
    fetchKeywords()
  }
}, { deep: true })

const fetchKeywords = async () => {
  isLoading.value = true
  error.value = null

  try {
    const params = {
      categoryId: props.mainKeyword.id,
      assetList: [assetStore.assetId],
      pagination: 0,
      ...(datePickerModel.value.start &&
          datePickerModel.value.end && {
            startDate: moment(datePickerModel.value.start).utc().format(),
            endDate: moment(datePickerModel.value.end).utc().format(),
          }),
    }
    const response = await axios.post(dashboard.getKeywordAnalysis, params)

    console.log('Keyword Analysis Response:', response.data.data)
    keywords.value = response.data.data.keywords.map((keyword) => ({
      id: keyword.keywordId,
      name: keyword.keywordName,
      volume: keyword.volume || 0,
      suggested: Number(keyword.type) === 2,
      position: keyword.positions,
    }))
  } catch (err) {
    console.log(err)
    error.value = 'Keywordler yüklenirken bir hata oluştu. Lütfen tekrar deneyin.'
    console.error('API Error:', err)
  } finally {
    isLoading.value = false
  }
}

const closeModal = () => {
  showModal.value = false
  keywords.value = []
  searchQuery.value = ''
  sortField.value = 'volume'
  sortDirection.value = 'desc'
  sourceFilter.value = 'all'
  error.value = null
  datePickerModel.value.end = null
  datePickerModel.value.start = null
}
</script>