<template>
  <nav class="bg-white fixed w-full z-[100] h-14 border-b border-gray-200">
    <div class="flex items-center justify-between h-full header-layout">
      <div class="flex items-center space-x-6">
        <button
          @click="navigation.toggleSidebar"
          class="rounded-lg flex items-center transition-all duration-200"
          :class="[
            showSidebarToggle
              ? 'hover:bg-gray-100 text-gray-700'
              : 'text-gray-300 cursor-not-allowed',
          ]"
          :disabled="!showSidebarToggle"
        >
          <span class="material-symbols-outlined !text-[33px]">menu</span>
        </button>

        <router-link
          to="/assets?tab=entities"
          class="items-center space-x-2 hidden md:flex"
        >
          <img
            src="../../../../assets/logo/logoA.svg"
            alt="logo"
            class="h-11 w-auto"
          />
        </router-link>

        <!-- Project Selector - Sadece desktop'ta görünür -->
        <div
          v-if="showItemsForRoutes"
          class="hidden lg:relative lg:flex border rounded-md border-gray-300"
        >
          <button
            @click="emit('showProjectModal', true)"
            class="flex items-center gap-2 px-3 py-1.5 rounded-lg hover:bg-gray-100 transition-colors duration-200"
          >
            <span
              class="text-xs font-medium text-gray-700 truncate max-w-[150px]"
              >{{ currentProject }}</span
            >
            <span class="material-symbols-outlined !text-[18px] text-gray-500"
              >arrow_drop_down</span
            >
          </button>
        </div>
      </div>

      <div class="flex items-center space-x-2">
        <!-- Her zaman görünecek öğeler -->
        <feature-menu v-if="showItemsForRoutes" />
        <notifications v-if="!route.path.startsWith('/users')" />

        <!-- Desktop'ta görünecek öğeler -->
        <language-menu
          type="text"
          :class="
            route.path.startsWith('/assets')
              ? 'inline-block'
              : 'hidden md:inline-block'
          "
        />
        <profile :name="profileName" />
      </div>
    </div>

    <ProjectSelectionModal v-model="showProjectModal" />
  </nav>
</template>

<script setup>
import { useNavigationStore } from "@/stores/navigationStore";
import { useAssetStore } from "@/stores/asset";
import { useAuthStore } from "@/stores/auth";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import Notifications from "./components/Notifications.vue";
import LanguageMenu from "../../../common/language.vue";
import Profile from "../components/profile.vue";
import ProjectSelectionModal from "./components/ProjectSelectionModal.vue";
import FeatureMenu from "@/components/navigation/layout/topNavbar/components/FeatureMenu.vue";

const route = useRoute();
const navigation = useNavigationStore();
const assetStore = useAssetStore();
const authStore = useAuthStore();
const showProjectModal = ref(false);

const emit = defineEmits(["showProjectModal"]);

const showSidebarToggle = computed(() => {
  if (route.path.startsWith("/assets")) return false;
  if (route.path.startsWith("/users")) return false;
  return route.path !== "/";
});

const showItemsForRoutes = computed(() => {
  return (
    !route.path.startsWith("/auth/") &&
    route.path !== "/" &&
    route.name !== "notFound" &&
    !route.path.startsWith("/assets") &&
    !route.path.startsWith("/users")
  );
});

const currentProject = computed(() => {
  return assetStore.assetName || "Select Project";
});

const profileName = computed(() => {
  return authStore.userData.fullname;
});
</script>
